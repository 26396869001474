@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;

  src: local('Raleway'), local('RalewayThin'),
    url('assets/fonts/Relaway/Raleway-Thin.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;

  src: local('Raleway'), local('RalewayLight'),
    url('assets/fonts/Relaway/Raleway-Light.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;

  src: local('Raleway'), local('RalewayRegular'),
    url('assets/fonts/Relaway/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;

  src: local('Raleway'), local('RalewayItalic'),
    url('assets/fonts/Relaway/Raleway-Italic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;

  src: local('Raleway'), local('RalewayMedium'),
    url('assets/fonts/Relaway/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;

  src: local('Raleway'), local('RalewaySemiBold'),
    url('assets/fonts/Relaway/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-weight: 700;

  src: local('Raleway'), local('RalewayBold'),
    url('assets/fonts/Relaway/Raleway-Bold.ttf') format('truetype');
}

body {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
}

* {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;

    border: 3px solid #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;

    border: 3px solid #c1c1c1;
    border-radius: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
